import { container, vivoblueColor } from "assets/jss/vivoleo-react-kit.js";

import image from "assets/img/homepage/plant-2798849_1920.jpg";
import { vivoFont, vivoFontGrayColor } from "assets/jss/vivoleo-react-kit";

const webDevQuoteStyle = {
  section: {
    minHeight: "110vh",
    maxHeight: "auto",
    overflow: "hidden",
    padding: "20px 0",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    margin: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
    backgroundImage: "url(" + image + ")",
  },
  container: {
    ...container,
    background: "rgba(255, 255, 255, 0.7)",
  },
  quoteCard: {
    width: "100%",
    padding: "0.8rem 1.2rem",
    "@media (min-width: 576px)": {
      height: "auto",
    },
    "@media (min-width: 768px)": {
      height: "auto",
    },
    "@media (min-width: 960px)": {
      height: "auto",
    },
    "@media (min-width: 1024px)": {
      height: "auto",
    },
    "@media (min-width: 1280px)": {
      height: "750px",
    },
    "@media (min-width: 1920px)": {
      height: "750px",
    },
    justifyContent: "space-between",
    flexDirection: "column",
    display: "flex",
  },
  vivoBlueBackground: {
    background:
      "linear-gradient(151deg, rgba(51, 143, 218, 0.8), rgba(51, 143, 218, 0.0) 12%),\n  linear-gradient(336deg, rgba(255, 193, 36, 0.8), rgba(255, 193, 36, 0) 12%)",
    "&:hover,&:focus": {
      background: "rgba(237, 247, 248, 0.7)",
    },
  },
  vivoBlueBackground2: {
    background:
      "linear-gradient(208deg, rgba(51, 143, 218, 0.8), rgba(51, 143, 218, 0.0) 12%),\n  linear-gradient(26deg, rgba(255, 193, 36, 0.8), rgba(255, 193, 36, 0) 12%)",
    "&:hover,&:focus": {
      background: "rgba(237, 247, 248, 0.7)",
    },
  },
  title: {
    textAlign: "center !important",
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: "2rem",
    color: vivoFontGrayColor,
    background: "rgba(237, 247, 248, 0.7)",
    lineHeight: "6rem",
    "&:hover,&:focus": {
      background: "rgba(255, 255, 255, 0.7)",
      color: vivoblueColor,
      boxShadow:
        "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)",
    },
  },
  space30: {
    height: "30px",
    display: "block",
  },
  space50: {
    height: "50px",
    display: "block",
  },
  textCenter: {
    textAlign: "center",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  contentText: {
    ...vivoFont,
    "&:hover,&:focus": {
      background: "rgba(255, 255, 255, 0.7)",
      color: vivoFontGrayColor,
      fontWeight: "600",
      boxShadow:
        "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)",
    },
  },
  stickBottom: {
    justifyContent: "center",
    display: "flex",
  },
  topSpace: {
    marginTop: "10px",
  },
};

export default webDevQuoteStyle;
