import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
//import styles from "assets/jss/vivoleo-react-kit/views/componentsSections/pillsStyle.js";
import styles from "assets/jss/vivoleo-react-kit/views/componentsSections/featuresStyle.js";
import mediaImage from "assets/img/homepage/social-media-2786261_1920.jpg";
//import Button from "components/CustomButtons/Button.js";
//import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(styles);

export default function SectionUXStrategy() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.section} id="uxstrategy">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card className={classes.uxCard}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt={t("wordslib.userfriendly")}
                  image={mediaImage}
                  title={t("wordslib.userfriendly")}
                  className={classes.imgRounded + " " + classes.imgFluid}
                />
              </CardActionArea>
              <div className={classes.space30} />
              <CardActionArea>
                <CardContent>
                  <Typography
                    className={classes.textCenter}
                    gutterBottom
                    variant="h6"
                    component="h5"
                  >
                    {t("wordslib.userfriendly")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card className={classes.uxCard}>
              <CardActionArea>
                <CardContent>
                  <Typography
                    className={classes.textCenter}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    {[
                      t("wordslib.UX"),
                      t("wordslib.strategy"),
                      " & ",
                      t("wordslib.UI"),
                      t("wordslib.design"),
                    ]}
                    <br />
                    <div className={classes.space50} />
                  </Typography>
                  <Typography
                    className={classes.textLeft}
                    gutterBottom
                    component="h2"
                  >
                    {t("wordslib.uxstratege1")} <br />
                    {t("wordslib.uxstratege2")} <br />
                    {t("wordslib.uxstratege3")} <br />
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
