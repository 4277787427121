import {
  container,
  title,
  vivoFontGrayColor,
  vivoblueColor,
} from "assets/jss/vivoleo-react-kit.js";
import image from "assets/img/homepage/ecommerce-3562005_1920.jpg";

const ourServiceStyle = {
  section: {
    width: "100vw",
    height: "auto",
    overflow: "hidden",
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "0px",
    marginRight: "0px",
    padding: "0",
    border: "0",
    display: "block",
    backgroundImage: "url(" + image + ")",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  container: {
    ...container,
    width: "100vw",
  },
  space50: {
    height: "50px",
    display: "block",
  },
  space30: {
    height: "30px",
    display: "block",
  },
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  titleCenter: {
    font: "inherit",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center !important",
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: "2rem",
    color: vivoFontGrayColor,
    background: "rgba(237, 247, 248, 0.7)",
    lineHeight: "6rem",
    "&:hover,&:focus": {
      background: "rgba(255, 255, 255, 0.7)",
      color: vivoblueColor,
      boxShadow:
        "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)",
    },
  },
  textCenter: {
    textAlign: "center",
    fontWeight: "600",
  },
  textLeft: {
    textAlign: "left",
  },
  vivoCard: {
    maxWidth: "100%",
    padding: "0.8rem 1.2rem",
    "@media (max-width: 575px)": {
      height: "400px",
    },
    "@media (min-width: 576px)": {
      height: "400px",
    },
    "@media (min-width: 768px)": {
      height: "500px",
    },
    "@media (min-width: 960px)": {
      height: "400px",
    },
    "@media (min-width: 1024px)": {
      height: "400px",
    },
    "@media (min-width: 1280px)": {
      height: "400px",
    },
    "@media (min-width: 1920px)": {
      height: "400px",
    },
    justifyContent: "space-between",
    flexDirection: "column",
    display: "flex",
  },
  stickBottom: {
    justifyContent: "center",
    display: "flex",
  },
  cardActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "16px",
  },
  topSpace: {
    marginTop: "10px",
  },
};

export default ourServiceStyle;
