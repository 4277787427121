/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import TranslateIcon from '@material-ui/icons/Translate';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/vivoleo-react-kit/components/headerLinksStyle.js";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const selectLang=()=>{
    if(i18n.language==='en' ){
      i18n.changeLanguage('zh');
    }
    else{
      i18n.changeLanguage('en');
    }
  
  }
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          type="submit"
          onClick={selectLang}
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <TranslateIcon className={classes.icons} /> {t('navbar.english')}
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={t('navbar.aboutus')}
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link
              to="#" 
              className={classes.dropdownLink}
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("introduction")
                  .scrollIntoView();
              }}
            >
              {t('wordslib.introduction')}
            </Link>,
            <Link 
              to="#" 
              className={classes.dropdownLink}
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("servicelist")
                  .scrollIntoView();
              }}
            >
              {t('wordslib.ourservice')}
            </Link>,
            <Link 
              to="#" 
              className={classes.dropdownLink}
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("keyFeatures")
                  .scrollIntoView();
              }}
            >
              {t("navbar.keyfeatures")}
            </Link>,
            <Link 
              to="#" 
              className={classes.dropdownLink}
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("worklist")
                  .scrollIntoView();
              }}
            >
              {t('wordslib.ourwork')}
            </Link>,
            <a
              href="https://works.vivoleo.com/#login"
              target="_blank"
              className={classes.dropdownLink}
            >
              {[t('wordslib.user'),t('wordslib.login')]}
            </a>,
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={t('navbar.services')}
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={VerticalSplitIcon}
          dropdownList={[
            <Link 
              to="#" 
              className={classes.dropdownLink}
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("webdev")
                  .scrollIntoView();
              }}          
            >
              {t('wordslib.web')}{t('wordslib.development')}
            </Link>,
            <Link 
              to="#" 
              className={classes.dropdownLink}
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("webmaint")
                  .scrollIntoView();
              }}          
            >
              {t('wordslib.web')}{t('wordslib.maintenance')}
            </Link>,
            <Link
              to="#" 
              className={classes.dropdownLink}
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("videos")
                  .scrollIntoView();
              }}
            >
              {t('wordslib.video')} & {t('wordslib.photography')}
            </Link>,
            <Link
              to="#" 
              className={classes.dropdownLink}
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("uxstrategy")
                  .scrollIntoView();
              }}            
            >
              {t('wordslib.UX')}{t('wordslib.strategy')}
            </Link>,
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
          href="#"
          color="transparent"
          target="_blank"
          className={classes.navLink}
          onClick={(e) => {
            e.preventDefault();
            document
              .getElementById("contactus")
              .scrollIntoView();
          }}
        >
          <ContactPhoneIcon className={classes.icons} /> {t('contact.contactus')}
        </Button>    
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="wechat-tooltip"
          title ={[t('wordslib.followuson'), t('wordslib.wechat')]}
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >  
        {/* 
          <Link to="WeixinmpPage"  className={classes.navLink}>
            <i className={classes.socialIcons + " fab fa-weixin"} />
          </Link>
        */} 
          <Button
            href="https://works.vivoleo.com/?page_id=2138"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-weixin"} /> 
          </Button>            
        </Tooltip>          
      </ListItem>
      <ListItem className={classes.listItem}>
        {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/}
        <Tooltip
          id="instagram-twitter"
          title= {[t('wordslib.followuson'), t('wordslib.twitter')]}
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/vivoleodev"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title={[t('wordslib.followuson'), t('wordslib.facebook')]}
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/vivoleodev"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title={[t('wordslib.followuson'), t('wordslib.instagram')]}
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/vivoleodev"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
