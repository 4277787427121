import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { HashRouter, Route, Switch } from "react-router-dom";

import "assets/scss/vivoleo-react-kit.scss?v=1.10.0";

// pages for this product
import Components from "views/Components/Components.js";
import "./i18n";

var hist = createBrowserHistory();

/*
ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/" component={Components} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
*/
ReactDOM.render(
  <HashRouter history={hist}>
    <Switch>
      <Route path="/" component={Components} />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
