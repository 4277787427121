import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons
//import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import vivoleowechatqr from "assets/img/icons/wechatid.jpg";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/vivoleo-react-kit/views/componentsSections/contactusStyle.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function SectionContactUs() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.section} id="contactus">
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} lg={6}>
            <Card>
              <CardHeader className={classes.cardHeader}>
                <h2>{t("contact.contactus")}</h2>
              </CardHeader>
              <p className={classes.divider}>{t("contact.t1")}</p>
              <p className={classes.divider}>{t("contact.t2")}</p>
              <CardBody>
                <div className={classes.socialLine}>
                  <Grid item xs container>
                    <Grid item xs={2} sm={3} md={3}>
                      <Typography
                        gutterBottom
                        className={classes.contactFont}
                        align="right"
                      >
                        <ContactPhoneIcon className={classes.inputIconsColor} />
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3}>
                      <Typography
                        gutterBottom
                        className={classes.contactFont}
                        align="center"
                      >
                        {t("contact.phone")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        className={classes.contactFont}
                        style={{ cursor: "pointer" }}
                        align="left"
                      >
                        (+1)647-936-5296
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs container>
                    <Grid item xs={2} sm={3} md={3}>
                      <Typography
                        gutterBottom
                        className={classes.contactFont}
                        align="right"
                      >
                        <Email className={classes.inputIconsColor} />
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3}>
                      <Typography
                        gutterBottom
                        className={classes.contactFont}
                        align="center"
                      >
                        {t("contact.email")}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={6}>
                      <Typography
                        gutterBottom
                        className={classes.contactFont}
                        style={{ cursor: "pointer" }}
                        align="left"
                      >
                        cs@vivoleo.com
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs container>
                    <Grid item xs={2} sm={3} md={3}>
                      <Typography
                        gutterBottom
                        className={classes.contactFont}
                        align="right"
                      >
                        <i className={classes.socialIcons + " fab fa-weixin"} />
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3}>
                      <Typography
                        gutterBottom
                        className={classes.contactFont}
                        align="center"
                      >
                        {t("navbar.wechat")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        className={classes.contactFont}
                        style={{ cursor: "pointer" }}
                        align="left"
                      >
                        vivoleodev
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs container alignItems="center" justify="center">
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="body1" align="center">
                        {t("contact.t3")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          alt={t("navbar.wechat")}
                          image={vivoleowechatqr}
                          title={t("navbar.wechat")}
                          className={
                            classes.imgRounded + " " + classes.imgFluid
                          }
                        />
                      </CardActionArea>
                    </Grid>
                  </Grid>
                  <Button
                    justIcon
                    href="https://twitter.com/vivoleodev"
                    target="_blank"
                    color="transparent"
                  >
                    <i className={classes.socialIcons + " fab fa-twitter"} />
                  </Button>
                  <Button
                    justIcon
                    href="https://www.facebook.com/vivoleodev"
                    target="_blank"
                    color="transparent"
                  >
                    <i className={classes.socialIcons + " fab fa-facebook"} />
                  </Button>
                  <Button
                    justIcon
                    href="https://www.instagram.com/vivoleodev"
                    target="_blank"
                    color="transparent"
                  >
                    <i className={classes.socialIcons + " fab fa-instagram"} />
                  </Button>
                </div>
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <Button
                  color="vivoblue"
                  href="https://www.vivoleo.com/#login"
                  target="_blank"
                >
                  {[t("wordslib.user"), t("wordslib.login")]}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
