import { container, title } from "assets/jss/vivoleo-react-kit.js";

import imagesStyles from "assets/jss/vivoleo-react-kit/imagesStyles.js";
import { vivoFontGrayColor, vivoblueColor } from "assets/jss/vivoleo-react-kit";

const ourWorkStyle = {
  section: {
    padding: "70px 0",
  },
  container,
  space50: {
    height: "50px",
    display: "block",
  },
  space30: {
    height: "30px",
    display: "block",
  },
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
    width: "100%",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  marginLeft: {
    marginLeft: "auto !important",
  },
  ...imagesStyles,
  titleCenter: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
  },
  textCenter: {
    textAlign: "center",
    fontWeight: "600",
  },
  textLeft: {
    textAlign: "left",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  ourworkCard: {
    maxWidth: "100%",
    padding: "0.8rem 1.2rem",
    "@media (min-width: 320px)": {
      height: "320px",
    },
    "@media (min-width: 576px)": {
      height: "320px",
    },
    "@media (min-width: 768px)": {
      height: "350px",
    },
    "@media (min-width: 960px)": {
      height: "300px",
    },
    "@media (min-width: 1024px)": {
      height: "300px",
    },
    "@media (min-width: 1280px)": {
      height: "300px",
    },
    "@media (min-width: 1920px)": {
      height: "300px",
    },
    justifyContent: "space-between",
    flexDirection: "column",
    display: "flex",
  },
  ourworkCard2: {
    maxWidth: "100%",
    padding: "0",
    maigin: "0",
    "@media (max-width: 575px)": {
      height: "200px",
    },
    "@media (min-width: 576px)": {
      height: "200px",
    },
    "@media (min-width: 768px)": {
      height: "220px",
    },
    "@media (min-width: 960px)": {
      height: "200px",
    },
    "@media (min-width: 1024px)": {
      height: "200px",
    },
    "@media (min-width: 1280px)": {
      height: "200px",
    },
    "@media (min-width: 1920px)": {
      height: "200px",
    },
    justifyContent: "space-between",
    flexDirection: "column",
    display: "flex",
  },
  stickBottom: {
    justifyContent: "center",
    display: "flex",
  },
  btnStickBottom: {
    marginBottom: "10px",
  },
  cardActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "16px",
  },
  topSpace: {
    marginTop: "10px",
  },
  bottmButton: {
    textAlign: "center !important",
    textTransform: "uppercase",
    fontWeight: "600",
    fontSize: "2rem",
    color: vivoFontGrayColor,
    background: "rgba(237, 247, 248, 0.7)",
    lineHeight: "6rem",
    "&:hover,&:focus": {
      background: "rgba(255, 255, 255, 0.7)",
      color: vivoblueColor,
      boxShadow:
        "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)",
    },
  },
};

export default ourWorkStyle;
