import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import InputAdornment from "@material-ui/core/InputAdornment";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons
//import People from "@material-ui/icons/People";
//import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";

import styles from "assets/jss/vivoleo-react-kit/views/componentsSections/webMaintQuoteStyle.js";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

export default function SectionWebMaintQuote() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.section} id="webmaintquote">
      <div className={classes.container}>
        <div className={classes.title}>
          {[t("wordslib.choose"), t("wordslib.theperfectplan")]}
        </div>
        <div className={classes.space30} />
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} lg={4} className={classes.topSpace}>
            <Card className={[classes.quoteCard, classes.vivoBlueBackground2]}>
              <CardActionArea>
                <CardContent>
                  <Typography
                    className={[classes.textCenter, classes.upperCase]}
                    gutterBottom
                    variant="h3"
                    component="h2"
                  >
                    $50
                  </Typography>
                  <Typography
                    className={[
                      classes.textCenter,
                      classes.upperCase,
                      classes.subTitleGray,
                    ]}
                    gutterBottom
                    variant="subtitle2"
                  >
                    {t("wordslib.permonth")}
                  </Typography>
                  <Typography
                    className={classes.textCenter}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    {[t("wordslib.small"), t("wordslib.business")]}
                  </Typography>
                  <div className={classes.space30} />
                  <Typography
                    className={[classes.textLeft, classes.contentText]}
                    gutterBottom
                    component="h2"
                  >
                    <DoneRoundedIcon />
                    {[t("wordslib.monthly"), t("wordslib.backups")]}
                    <br />
                    <DoneRoundedIcon />
                    {[t("wordslib.website"), t("wordslib.monitoring")]}
                    <br />
                    <DoneRoundedIcon />
                    {t("wordslib.keepworking")}
                    <br />
                    <DoneRoundedIcon />
                    {t("wordslib.troubleshooting")}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.full"),
                      t("wordslib.website"),
                      " & ",
                      t("wordslib.database"),
                      t("wordslib.backups"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[t("wordslib.framework"), t("wordslib.upgrades")]}
                    <br />
                    <DoneRoundedIcon />
                    {[t("wordslib.cmsplugin"), t("wordslib.updates")]}
                    <br />
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActionArea className={classes.stickBottom}>
                <CardContent>
                  <Button
                    color="vivoblue"
                    transparent
                    href="#"
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("contactus").scrollIntoView();
                    }}
                  >
                    {" "}
                    {t("wordslib.getstarted")}
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={8} lg={4} className={classes.topSpace}>
            <Card className={[classes.quoteCard, classes.vivoBlueBackground]}>
              <CardActionArea>
                <CardContent>
                  <Typography
                    className={[classes.textCenter, classes.upperCase]}
                    gutterBottom
                    variant="h3"
                    component="h2"
                  >
                    $100
                  </Typography>
                  <Typography
                    className={[
                      classes.textCenter,
                      classes.upperCase,
                      classes.subTitleGray,
                    ]}
                    gutterBottom
                    variant="subtitle2"
                  >
                    {t("wordslib.permonth")}
                  </Typography>
                  <Typography
                    className={classes.textCenter}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    {[t("wordslib.medium"), t("wordslib.business")]}
                  </Typography>
                  <div className={classes.space30} />
                  <Typography
                    className={[classes.textLeft, classes.contentText]}
                    gutterBottom
                    component="h2"
                  >
                    <DoneRoundedIcon />
                    {[t("wordslib.weekly"), t("wordslib.backups")]}
                    <br />
                    <DoneRoundedIcon />
                    {[t("wordslib.website"), t("wordslib.monitoring")]}
                    <br />
                    <DoneRoundedIcon />
                    {t("wordslib.keepworking")}
                    <br />
                    <DoneRoundedIcon />
                    {t("wordslib.troubleshooting")}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.full"),
                      t("wordslib.website"),
                      " & ",
                      t("wordslib.database"),
                      t("wordslib.backups"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[t("wordslib.framework"), t("wordslib.upgrades")]}
                    <br />
                    <DoneRoundedIcon />
                    {[t("wordslib.cmsplugin"), t("wordslib.updates")]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.website"),
                      t("wordslib.running"),
                      t("wordslib.analytics"),
                      t("wordslib.report"),
                    ]}
                    <br />
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActionArea className={classes.stickBottom}>
                <CardContent>
                  <Button
                    color="vivoblue"
                    transparent
                    href="#"
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("contactus").scrollIntoView();
                    }}
                  >
                    {" "}
                    {t("wordslib.getstarted")}
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={8} lg={4} className={classes.topSpace}>
            <Card className={[classes.quoteCard, classes.vivoBlueBackground2]}>
              <CardActionArea>
                <CardContent>
                  <Typography
                    className={[classes.textCenter, classes.upperCase]}
                    gutterBottom
                    variant="h3"
                    component="h2"
                  >
                    $300
                  </Typography>
                  <Typography
                    className={[
                      classes.textCenter,
                      classes.upperCase,
                      classes.subTitleGray,
                    ]}
                    gutterBottom
                    variant="subtitle2"
                  >
                    {t("wordslib.permonth")}
                  </Typography>
                  <Typography
                    className={classes.textCenter}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    {[t("wordslib.corporate"), t("wordslib.business")]}
                  </Typography>
                  <div className={classes.space30} />
                  <Typography
                    className={[classes.textLeft, classes.contentText]}
                    gutterBottom
                    component="h2"
                  >
                    <DoneRoundedIcon />
                    {[t("wordslib.daily"), t("wordslib.backups")]}
                    <br />
                    <DoneRoundedIcon />
                    {[t("wordslib.website"), t("wordslib.monitoring")]}
                    <br />
                    <DoneRoundedIcon />
                    {t("wordslib.keepworking")}
                    <br />
                    <DoneRoundedIcon />
                    {t("wordslib.troubleshooting")}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.full"),
                      t("wordslib.website"),
                      " & ",
                      t("wordslib.database"),
                      t("wordslib.backups"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[t("wordslib.framework"), t("wordslib.upgrades")]}
                    <br />
                    <DoneRoundedIcon />
                    {[t("wordslib.cmsplugin"), t("wordslib.updates")]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.website"),
                      t("wordslib.running"),
                      t("wordslib.analytics"),
                      t("wordslib.report"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.continuously"),
                      t("wordslib.optimizing"),
                      t("wordslib.website"),
                    ]}
                    <br />
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActionArea className={classes.stickBottom}>
                <CardContent>
                  <Button
                    color="vivoblue"
                    transparent
                    href="#"
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("contactus").scrollIntoView();
                    }}
                  >
                    {" "}
                    {t("wordslib.getstarted")}
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
