import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionAboutUs from "./Sections/SectionAboutUs.js";
import SectionOurServices from "./Sections/SectionOurServices.js";
import SectionFeatures from "./Sections/SectionFeatures.js";
import SectionUXStrategy from "./Sections/SectionUXStrategy.js";
import SectionVideo from "./Sections/SectionVideo.js";
import SectionWebDev from "./Sections/SectionWebDev.js";
import SectionWebDevQuote from "./Sections/SectionWebDevQuote.js";
import SectionWebMaint from "./Sections/SectionWebMaint.js";
import SectionWebMaintQuote from "./Sections/SectionWebMaintQuote.js";
import SectionOurWork from "./Sections/SectionOurWork.js";
import SectionContactUs from "./Sections/SectionContactUs.js";
import styles from "assets/jss/vivoleo-react-kit/views/components.js";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

//export default function Components(props) {
function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Header
        brand="VIVOLEO"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "#65fbfb",
        }}
        {...rest}
      />
      <Parallax
        homePageFilter
        image={require("assets/img/531367701.jpg").default}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>{t("homePage.title")}</h1>
                <div className={classes.space30}></div>
                <div className={classes.subtitle}>
                  <Button
                    className={classes.btnColor}
                    round
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("contactus").scrollIntoView();
                    }}
                  >
                    {t("contact.contactus")}
                  </Button>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.maintwo, classes.mainRaised)}>
        <SectionAboutUs />
      </div>
      <div className={classes.sectionSpace}></div>
      <div className={classes.main}>
        <SectionOurServices />
        <SectionFeatures />
        <SectionUXStrategy />
        <SectionVideo />
        <SectionWebDev />
        <SectionWebDevQuote />
        <SectionWebMaint />
        <SectionWebMaintQuote />
        <SectionOurWork />
        <SectionContactUs />
      </div>
      <div className={classes.sectionSpace}></div>
      <Footer />
    </div>
  );
}
export default function WrappedApp() {
  return (
    <Suspense fallback="...is loading">
      <Components />
    </Suspense>
  );
}
