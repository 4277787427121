import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/vivoleo-react-kit/views/componentsSections/aboutUsStyle.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function SectionHelpYou() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.section} id="introduction">
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2>{t("navbar.aboutus")}</h2>
            <div className={classes.contentText}>
              {t("content.introduction.t1")}
            </div>
            <div className={classes.contentText}>
              {t("content.introduction.t2")}
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
