import { container, title } from "assets/jss/vivoleo-react-kit.js";

import imagesStyles from "assets/jss/vivoleo-react-kit/imagesStyles.js";

const featureStyle = {
  section: {
    padding: "70px 0",
  },
  container,
  space50: {
    height: "50px",
    display: "block",
  },
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
    width: "100%",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  marginLeft: {
    marginLeft: "auto !important",
  },
  ...imagesStyles,
  titleCenter: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
  },
  textCenter: {
    textAlign: "center",
    fontWeight: "600",
  },
  textLeft: {
    textAlign: "left",
    fontSize: "1.2rem",
  },
  textLeftTitle: {
    textAlign: "left",
    fontSize: "1.2rem",
    fontWeight: "600",
  },
  vivoCard: {
    maxWidth: "100%",
    padding: "0.8rem 1.2rem",
    minHeight: "350px",
  },
  featureCard: {
    width: "100%",
    padding: "0.8rem 1.2rem",
    "@media (min-width: 576px)": {
      height: "auto",
    },
    "@media (min-width: 768px)": {
      height: "auto",
    },
    "@media (min-width: 960px)": {
      height: "580px",
    },
    "@media (min-width: 992px)": {
      height: "500px",
    },
    "@media (min-width: 1200px)": {
      height: "500px",
    },
  },
  uxCard: {
    width: "100%",
    padding: "0.8rem 1.2rem",
    "@media (min-width: 576px)": {
      height: "auto",
    },
    "@media (min-width: 768px)": {
      height: "auto",
    },
    "@media (min-width: 960px)": {
      height: "550px",
    },
    "@media (min-width: 1024px)": {
      height: "480px",
    },
    "@media (min-width: 1280px)": {
      height: "450px",
    },
    "@media (min-width: 1920px)": {
      height: "450px",
    },
  },
  videoCard: {
    width: "100%",
    padding: "0.8rem 1.2rem",
    "@media (min-width: 576px)": {
      height: "auto",
    },
    "@media (min-width: 768px)": {
      height: "auto",
    },
    "@media (min-width: 960px)": {
      height: "550px",
    },
    "@media (min-width: 1024px)": {
      height: "480px",
    },
    "@media (min-width: 1280px)": {
      height: "450px",
    },
    "@media (min-width: 1920px)": {
      height: "450px",
    },
  },
  cardActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "16px",
  },
  btnColor: {
    backgroundColor: "#338fda",
  },
  paper: {
    height: "140px",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
};

export default featureStyle;
