/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/vivoleo-react-kit/components/footerStyle.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://home.vivoleo.com/"
                className={classes.block}
                target="_blank"
              >
                Vivoleo
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                className={classes.block}
                target="_blank"
                href="#"                    
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("introduction")
                    .scrollIntoView();
                }}
              >
                {t('navbar.aboutus')}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="#"
                className={classes.block}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("servicelist")
                    .scrollIntoView();
                }}
              >
                {t('wordslib.ourservice')}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="#"
                className={classes.block}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById("contactus")
                    .scrollIntoView();
                }}
              >
                {t('contact.contactus')}
              </a>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()}            
          <a
            href="https://home.vivoleo.com"
            className={aClasses}
            target="_blank"
          >
            &nbsp;&nbsp;Vivoleo Development Ltd All Right Reserved.
          </a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
