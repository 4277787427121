import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/vivoleo-react-kit/views/componentsSections/webMaintStyle.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function SectionWebMaint() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.section} id="webmaint">
      <div className={classes.container}>
        <GridContainer justify="center" className={classes.contentBackGround}>
          <GridItem xs={12} sm={12} md={8}>
            <h2>
              <div className={classes.upperCase}>
                {[t("wordslib.web"), t("wordslib.maintenance")]}
              </div>
            </h2>
            <h5>&nbsp;</h5>
            <div className={classes.contentText}>
              {t("wordslib.webmaint1")}
              {t("wordslib.webmaint2")}
            </div>
            <h5>&nbsp;</h5>
            <div className={classes.contentText}>
              {t("wordslib.webmaint3")}
              {t("wordslib.webmaint4")}
            </div>
            <h5>&nbsp;</h5>
            <Button
              color="vivoblue"
              transparent
              href="#"
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("webmaintquote").scrollIntoView();
              }}
            >
              {t("wordslib.getaquote")}
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
