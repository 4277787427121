import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
//import styles from "assets/jss/vivoleo-react-kit/views/componentsSections/pillsStyle.js";
import styles from "assets/jss/vivoleo-react-kit/views/componentsSections/featuresStyle.js";
import featuresImage from "assets/img/homepage/login-3938432_1920-1427x1024.jpg";
import Button from "components/CustomButtons/Button.js";
const useStyles = makeStyles(styles);

export default function SectionFeatures() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.section} id="keyFeatures">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card className={classes.featureCard}>
              <CardActionArea>
                <CardContent>
                  <Typography
                    className={classes.textCenter}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    {t("wordslib.keyfeatures")}
                    <div className={classes.space50} />
                  </Typography>
                  <Typography
                    className={classes.textLeft}
                    gutterBottom
                    component="h2"
                  >
                    <div className={classes.textLeftTitle}>
                      {t("wordslib.responsivewebdesign")} –
                    </div>
                    {t("wordslib.f1")}
                    <br />
                    <div className={classes.textLeftTitle}>
                      {t("wordslib.cms")} –
                    </div>
                    {t("wordslib.f2")} <br />
                    <div className={classes.textLeftTitle}>
                      {t("wordslib.ecsettings")} –
                    </div>
                    {t("wordslib.f3")}
                    <br />
                  </Typography>
                </CardContent>
              </CardActionArea>
              <div className={classes.space30} />
              <div className={[classes.cardActions, classes.textLeft]}>
                <Button
                  color="vivoblue"
                  transparent
                  href="#"
                  target="_blank"
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("webdevquote").scrollIntoView();
                  }}
                >
                  {t("wordslib.getaquote")}
                </Button>
              </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card className={classes.featureCard}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt={[t("wordslib.website"), t("wordslib.development")]}
                  image={featuresImage}
                  title={[t("wordslib.website"), t("wordslib.development")]}
                  className={classes.imgRounded + " " + classes.imgFluid}
                />
              </CardActionArea>
              <div className={classes.space50} />
              <CardActionArea>
                <CardContent>
                  <Typography
                    className={classes.textCenter}
                    gutterBottom
                    variant="h6"
                    component="h5"
                  >
                    {[
                      t("wordslib.responsivedesign"),
                      " & ",
                      t("wordslib.mobilefirst"),
                    ]}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
