import { container } from "assets/jss/vivoleo-react-kit";
import image from "assets/img/homepage/ecommerce-3562005_1920.jpg";
import { vivoFontGrayColor } from "assets/jss/vivoleo-react-kit";

const aboutUsStyle = {
  section: {
    minHeight: "30vh",
    maxHeight: "auto",
    // overflow: "hidden",
    padding: "0",
    //backgroundColor: "transparent",
    //background: "#333333",
    backgroundImage: "url(" + image + ")",
    backgroundPosition: "top center",
    backgroundSize: "contain",
    marginLeft: "20%",
    marginRight: "20%",
    //border: "0",
    //display: "flex",
    alignItems: "center",
  },
  container: {
    ...container,
    minHeight: "30vh",
    textAlign: "center !important",
    color: vivoFontGrayColor,
    background: "rgba(255, 255, 255, 0.9)",
    "&:hover,&:focus": {
      background: "rgba(51, 143, 218, 0.8)",
      color: "#FFFFFF",
      boxShadow:
        "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)",
    },
  },
  contentText: {
    fontSize: "1.2rem",
    textAlign: "left",
    padding: "20px 0",
    "&:hover,&:focus": {
      fontWeight: "600",
      boxShadow:
        "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)",
    },
  },
  upperCase: {
    textTransform: "uppercase",
  },
};

export default aboutUsStyle;
