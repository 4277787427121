import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import uxImage from "assets/img/homepage/ux2.png";
import designImage from "assets/img/homepage/design2.png";
import maintainImage from "assets/img/homepage/maintain2.png";
import videoImage from "assets/img/homepage/video2.png";
import styles from "assets/jss/vivoleo-react-kit/views/componentsSections/ourServiceStyle.js";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
//import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function SectionOurServices() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.section} id="servicelist">
      <div className={classes.container}>
        <div className={classes.titleCenter}>{t("navbar.services")}</div>
        <div className={classes.space30} />
        <GridContainer>
          <GridItem xs={6} sm={6} md={3} className={classes.topSpace}>
            <Card className={classes.vivoCard}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt={[t("wordslib.UX"), t("wordslib.strategy")]}
                  image={uxImage}
                  title={[t("wordslib.UX"), t("wordslib.strategy")]}
                  className={classes.imgRoundedCircle + " " + classes.imgFluid}
                />
                <CardContent>
                  <Typography
                    className={classes.textCenter}
                    gutterBottom
                    fontSize="1.0rem"
                    component="h2"
                  >
                    {[t("wordslib.UX"), t("wordslib.strategy"), " & "]}
                    {[t("wordslib.UI"), t("wordslib.design")]}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActionArea className={classes.stickBottom}>
                <CardContent>
                  <Button
                    color="vivoblue"
                    transparent
                    href="#"
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("uxstrategy").scrollIntoView();
                    }}
                  >
                    {" "}
                    {t("wordslib.readmore")}
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
          <GridItem xs={6} sm={6} md={3} className={classes.topSpace}>
            <Card className={classes.vivoCard}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt={[t("wordslib.website"), t("wordslib.development")]}
                  image={designImage}
                  title={[t("wordslib.website"), t("wordslib.development")]}
                  className={classes.imgRoundedCircle + " " + classes.imgFluid}
                />
                <CardContent>
                  <Typography
                    className={classes.textCenter}
                    gutterBottom
                    fontSize="1.0rem"
                    component="h2"
                  >
                    {[t("wordslib.website"), t("wordslib.development")]}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActionArea className={classes.stickBottom}>
                <CardContent>
                  <Button
                    color="vivoblue"
                    transparent
                    href="#"
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("webdev").scrollIntoView();
                    }}
                  >
                    {" "}
                    {t("wordslib.readmore")}
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
          <GridItem xs={6} sm={6} md={3} className={classes.topSpace}>
            <Card className={classes.vivoCard}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt={[t("wordslib.website"), t("wordslib.maintenance")]}
                  image={maintainImage}
                  title={[t("wordslib.website"), t("wordslib.maintenance")]}
                  className={classes.imgRoundedCircle + " " + classes.imgFluid}
                />
                <CardContent>
                  <Typography
                    className={classes.textCenter}
                    gutterBottom
                    fontSize="1.0rem"
                    component="h2"
                  >
                    {[t("wordslib.website"), t("wordslib.maintenance")]}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActionArea className={classes.stickBottom}>
                <CardContent>
                  <Button
                    color="vivoblue"
                    transparent
                    href="#"
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("webmaint").scrollIntoView();
                    }}
                  >
                    {" "}
                    {t("wordslib.readmore")}
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
          <GridItem xs={6} sm={6} md={3} className={classes.topSpace}>
            <Card className={classes.vivoCard}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt={[t("wordslib.video"), "&", t("wordslib.photography")]}
                  image={videoImage}
                  title={[t("wordslib.video"), "&", t("wordslib.photography")]}
                  className={classes.imgRoundedCircle + " " + classes.imgFluid}
                />
                <CardContent>
                  <Typography
                    className={classes.textCenter}
                    gutterBottom
                    fontSize="1.0rem"
                    component="h2"
                  >
                    {[t("wordslib.video"), " & ", t("wordslib.photography")]}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActionArea className={classes.stickBottom}>
                <CardContent>
                  <Button
                    color="vivoblue"
                    transparent
                    href="#"
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("videos").scrollIntoView();
                    }}
                  >
                    {" "}
                    {t("wordslib.readmore")}
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer />
      </div>
      <div className={classes.space50} />
    </div>
  );
}
