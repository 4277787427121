import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import InputAdornment from "@material-ui/core/InputAdornment";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons
//import People from "@material-ui/icons/People";
//import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import classNames from "classnames";

import styles from "assets/jss/vivoleo-react-kit/views/componentsSections/webDevQuoteStyle.js";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

export default function SectionWebDevQuote() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.section} id="webdevquote">
      <div className={classes.container}>
        <div className={classes.title}>
          {[t("wordslib.choose"), t("wordslib.therightplan")]}
        </div>
        <div className={classes.space30} />
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} lg={4} className={classes.topSpace}>
            <Card
              className={classNames(
                classes.quoteCard,
                classes.vivoBlueBackground2
              )}
            >
              <CardActionArea>
                <CardContent>
                  <Typography
                    className={[classes.textCenter, classes.upperCase]}
                    gutterBottom
                    variant="h3"
                    component="h2"
                  >
                    $500
                  </Typography>
                  <Typography
                    className={[classes.textCenter, classes.upperCase]}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    {t("wordslib.basic")}
                  </Typography>
                  <div className={classes.space30} />
                  <Typography
                    className={[classes.textLeft, classes.contentText]}
                    gutterBottom
                    component="h2"
                  >
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.upto"),
                      " 5 ",
                      t("wordslib.pages"),
                      t("wordslib.web"),
                      t("wordslib.design"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {t("wordslib.responsivewebdesign")}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.self"),
                      t("wordslib.content"),
                      t("wordslib.management"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.social"),
                      t("wordslib.media"),
                      t("wordslib.integration"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.google"),
                      t("wordslib.map"),
                      t("wordslib.integration"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.upto"),
                      " 5 ",
                      t("wordslib.stock"),
                      t("wordslib.photoes"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[t("wordslib.turnaround"), " 1-2 ", t("wordslib.week")]}
                    <br />
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActionArea className={classes.stickBottom}>
                <CardContent>
                  <Button
                    color="vivoblue"
                    transparent
                    href="#"
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("contactus").scrollIntoView();
                    }}
                  >
                    {" "}
                    {t("wordslib.getstarted")}
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={8} lg={4} className={classes.topSpace}>
            <Card className={[classes.quoteCard, classes.vivoBlueBackground]}>
              <CardActionArea>
                <CardContent>
                  <Typography
                    className={[classes.textCenter, classes.upperCase]}
                    gutterBottom
                    variant="h3"
                    component="h2"
                  >
                    $1000
                  </Typography>
                  <Typography
                    className={[classes.textCenter, classes.upperCase]}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    {t("wordslib.standard")}
                  </Typography>
                  <div className={classes.space30} />
                  <Typography
                    className={[classes.textLeft, classes.contentText]}
                    gutterBottom
                    component="h2"
                  >
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.upto"),
                      " 10 ",
                      t("wordslib.pages"),
                      t("wordslib.web"),
                      t("wordslib.design"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {t("wordslib.responsivewebdesign")}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.self"),
                      t("wordslib.content"),
                      t("wordslib.management"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.social"),
                      t("wordslib.media"),
                      t("wordslib.integration"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.google"),
                      t("wordslib.map"),
                      t("wordslib.integration"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.upto"),
                      " 10 ",
                      t("wordslib.stock"),
                      t("wordslib.photoes"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.upto"),
                      " 3 ",
                      t("wordslib.banner"),
                      t("wordslib.design"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[t("wordslib.turnaround"), " 2-3 ", t("wordslib.week")]}
                    <br />
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActionArea className={classes.stickBottom}>
                <CardContent>
                  <Button
                    color="vivoblue"
                    transparent
                    href="#"
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("contactus").scrollIntoView();
                    }}
                  >
                    {" "}
                    {t("wordslib.getstarted")}
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={8} lg={4} className={classes.topSpace}>
            <Card className={[classes.quoteCard, classes.vivoBlueBackground2]}>
              <CardActionArea>
                <CardContent>
                  <Typography
                    className={[classes.textCenter, classes.upperCase]}
                    gutterBottom
                    variant="h3"
                    component="h2"
                  >
                    $3500
                  </Typography>
                  <Typography
                    className={[classes.textCenter, classes.upperCase]}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    {t("wordslib.ecommerce")}
                  </Typography>
                  <div className={classes.space30} />
                  <Typography
                    className={[classes.textLeft, classes.contentText]}
                    gutterBottom
                    component="h2"
                  >
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.upto"),
                      " 10 ",
                      t("wordslib.pages"),
                      t("wordslib.web"),
                      t("wordslib.design"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {t("wordslib.responsivewebdesign")}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.self"),
                      t("wordslib.content"),
                      t("wordslib.management"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.social"),
                      t("wordslib.media"),
                      t("wordslib.integration"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.google"),
                      t("wordslib.map"),
                      t("wordslib.integration"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.upto"),
                      " 10 ",
                      t("wordslib.stock"),
                      t("wordslib.photoes"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.upto"),
                      " 3 ",
                      t("wordslib.banner"),
                      t("wordslib.design"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.upto"),
                      " 20 ",
                      t("wordslib.product"),
                      t("wordslib.setup"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.merchant"),
                      t("wordslib.account"),
                      t("wordslib.setup"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.payment"),
                      t("wordslib.processor"),
                      t("wordslib.setup"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[t("wordslib.shipping"), t("wordslib.setup")]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.coupon"),
                      " & ",
                      t("wordslib.popup"),
                      t("wordslib.window"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[
                      t("wordslib.free"),
                      " & ",
                      t("wordslib.product"),
                      t("wordslib.training"),
                    ]}
                    <br />
                    <DoneRoundedIcon />
                    {[t("wordslib.turnaround"), " 4-8 ", t("wordslib.week")]}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActionArea className={classes.stickBottom}>
                <CardContent>
                  <Button
                    color="vivoblue"
                    transparent
                    href="#"
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("contactus").scrollIntoView();
                    }}
                  >
                    {" "}
                    {t("wordslib.getstarted")}
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
