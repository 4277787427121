import {
  container,
  sectionSpace,
  space30,
  space50,
  vivoblueColor,
} from "assets/jss/vivoleo-react-kit.js";

const componentsStyle = {
  container,
  sectionSpace,
  space30,
  space50,
  brand: {
    color: "#338fda",
    textAlign: "center",
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    //display: "inline-block",
    //display: "flex",
    position: "relative",
    //position: "absolute",
    //marginLeft: "10px",
    marginLeft: "10%",
    marginRight: "10%",
    //marginTop: "-20%",
  },
  subtitle: {
    fontSize: "3rem",
    //maxWidth: "500px",
    position: "relative",
    //margin: "10px 0 0",
    marginLeft: "10%",
    marginRight: "10%",
    //marginTop: "-20%",
  },
  main: {
    //background: "#65fbfb",
    background: "#FFFFFF",
    //background: "transparent",
    position: "relative",
    zIndex: "3",
  },
  maintwo: {
    //background: "#65fbfb",
    //background: "#FFFFFF",
    background: "transparent",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  link: {
    textDecoration: "none",
  },
  textCenter: {
    textAlign: "center",
  },
  btnColor: {
    backgroundColor: "#ffffff",
    color: vivoblueColor,
    fontSize: "1.3rem",
    fontWeight: "600",
    zIndex: "1000",
    "&:hover,&:focus": {
      backgroundColor: vivoblueColor,
      color: "#ffffff",
      boxShadow:
        "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)",
    },
  },
  filterColor: {
    "&:before": {
      //background: "rgba(51, 143, 218, 0.5)",
      background: "rgba(0, 0, 0, 0.5)",
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
    },
  },
};

export default componentsStyle;
