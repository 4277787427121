import { container, vivoFontGrayColor } from "assets/jss/vivoleo-react-kit";

const webMaintStyle = {
  section: {
    padding: "70px 0",
  },
  container: {
    ...container,
    textAlign: "center !important",
  },
  contentText: {
    fontSize: "1.2rem",
    textAlign: "left",
    "&:hover,&:focus": {
      color: vivoFontGrayColor,
      fontWeight: "600",
    },
  },
  upperCase: {
    textTransform: "uppercase",
  },
  contentBackGround: {
    background:
      "linear-gradient(336deg, rgba(248, 104, 79, 0.8), rgba(248, 104, 79, 0) 12%),\n linear-gradient(26deg, rgba(51, 143, 218, 0.8), rgba(51, 143, 218, 0) 12%),\n  linear-gradient(208deg, rgba(255, 193, 36, 0.8), rgba(255, 193, 36, 0) 12%),\n linear-gradient(151deg, rgba(70, 242, 64, 0.8), rgba(70, 242, 64, 0) 12%)",
    "&:hover,&:focus": {
      background:
        "linear-gradient(26deg, rgba(248, 104, 79, 0.8), rgba(248, 104, 79, 0) 12%),\n linear-gradient(336deg, rgba(51, 143, 218, 0.8), rgba(51, 143, 218, 0) 12%),\n  linear-gradient(151deg, rgba(255, 193, 36, 0.8), rgba(255, 193, 36, 0) 12%),\n linear-gradient(208deg, rgba(70, 242, 64, 0.8), rgba(70, 242, 64, 0) 12%)",
    },
  },
};

export default webMaintStyle;
