import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
//import styles from "assets/jss/vivoleo-react-kit/views/componentsSections/pillsStyle.js";
import styles from "assets/jss/vivoleo-react-kit/views/componentsSections/featuresStyle.js";
import videoImage from "assets/img/homepage/video-97053_1920.jpg";
import Button from "components/CustomButtons/Button.js";
//import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(styles);

export default function SectionVideo() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.section} id="videos">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card className={classes.videoCard}>
              <CardActionArea>
                <CardContent>
                  <Typography
                    className={[classes.textCenter, classes.upperCase]}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    {[t("wordslib.video"), " & ", t("wordslib.photography")]}
                    <br />
                    <div className={classes.space50} />
                  </Typography>
                  <Typography
                    className={classes.textLeft}
                    gutterBottom
                    component="h2"
                  >
                    {t("wordslib.video1")} <br />
                    {t("wordslib.video2")} <br />
                    {t("wordslib.video3")} <br />
                  </Typography>
                </CardContent>
              </CardActionArea>
              <div className={classes.space30} />
              <div className={[classes.cardActions, classes.textLeft]}>
                <Button
                  color="vivoblue"
                  transparent
                  href="https://works.vivoleo.com/?page_id=1889"
                  target="_blank"
                >
                  {t("wordslib.projectsamples")}
                </Button>
              </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card className={classes.videoCard}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt={[t("wordslib.video"), t("wordslib.photography")]}
                  image={videoImage}
                  title={[t("wordslib.video"), t("wordslib.photography")]}
                  className={classes.imgRounded + " " + classes.imgFluid}
                />
              </CardActionArea>
              <div className={classes.space30} />
              <CardActionArea>
                <CardContent>
                  <Typography
                    className={classes.textCenter}
                    gutterBottom
                    variant="h6"
                    component="h5"
                  >
                    {t("wordslib.multimediamarketing")}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
