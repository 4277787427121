import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import vivofootImage from "assets/img/homepage/Vivofoot.jpg";
import wotuneImage from "assets/img/homepage/Wotune.jpg";
import realestateImage from "assets/img/homepage/ryanqiu1.jpg";
import vivodecoImage from "assets/img/homepage/vivodeco1.jpg";
import trainingschoolImage from "assets/img/homepage/victoronto.jpg";
import gamingImage from "assets/img/homepage/A4L1.jpg";
import shoewarehouseImage from "assets/img/homepage/shoewh1.jpg";
import micromovieImage from "assets/img/homepage/MicroMovie.jpg";

import styles from "assets/jss/vivoleo-react-kit/views/componentsSections/ourWorkStyle.js";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
//import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function SectionOurWork() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.section} id="worklist">
      <div className={classes.container}>
        <div id="OurWork" className={classes.titleCenter}>
          <div className={classes.upperCase}>
            <h2>{t("wordslib.ourwork")}</h2>
          </div>
        </div>
        <div className={classes.space50} />
        <GridContainer>
          <GridItem xs={6} sm={6} md={3} className={classes.topSpace}>
            <Card className={classes.ourworkCard}>
              <a href="https://www.vivofoot.com/">
                <div className={classes.ourworkCard2}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={[t("wordslib.footware"), t("wordslib.store")]}
                      image={vivofootImage}
                      title={[t("wordslib.footware"), t("wordslib.store")]}
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                    <CardContent>
                      <Typography
                        className={classes.textCenter}
                        gutterBottom
                        fontSize="1.0rem"
                        component="h2"
                      >
                        {[t("wordslib.footware"), t("wordslib.store")]}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </div>
                <CardActionArea className={classes.stickBottom}>
                  <CardContent>
                    <Button color="vivoblue" transparent>
                      {t("wordslib.readmore")}
                    </Button>
                  </CardContent>
                </CardActionArea>
              </a>
            </Card>
          </GridItem>
          <GridItem xs={6} sm={6} md={3} className={classes.topSpace}>
            <Card className={classes.ourworkCard}>
              <a href="http://www.wotune.com">
                <div className={classes.ourworkCard2}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={[t("wordslib.music"), t("wordslib.sharing")]}
                      image={wotuneImage}
                      title={[t("wordslib.music"), t("wordslib.sharing")]}
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                    <CardContent>
                      <Typography
                        className={classes.textCenter}
                        gutterBottom
                        fontSize="1.0rem"
                        component="h2"
                      >
                        {[
                          t("wordslib.music"),
                          t("wordslib.sharing"),
                          t("wordslib.platform"),
                        ]}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </div>
                <CardActionArea className={classes.stickBottom}>
                  <CardContent>
                    <Button color="vivoblue" transparent>
                      {t("wordslib.readmore")}
                    </Button>
                  </CardContent>
                </CardActionArea>
              </a>
            </Card>
          </GridItem>
          <GridItem xs={6} sm={6} md={3} className={classes.topSpace}>
            <Card className={classes.ourworkCard}>
              <a href="https://works.vivoleo.com/?page_id=1508">
                <div className={classes.ourworkCard2}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={[t("wordslib.realestate"), t("wordslib.broker")]}
                      image={realestateImage}
                      title={[t("wordslib.realestate"), t("wordslib.broker")]}
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                    <CardContent>
                      <Typography
                        className={classes.textCenter}
                        gutterBottom
                        fontSize="1.0rem"
                        component="h2"
                      >
                        {[t("wordslib.realestate"), t("wordslib.broker")]}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </div>
                <CardActionArea className={classes.stickBottom}>
                  <CardContent>
                    <Button color="vivoblue" transparent>
                      {t("wordslib.readmore")}
                    </Button>
                  </CardContent>
                </CardActionArea>
              </a>
            </Card>
          </GridItem>
          <GridItem xs={6} sm={6} md={3} className={classes.topSpace}>
            <Card className={classes.ourworkCard}>
              <a href="https://www.vivodeco.com/">
                <div className={classes.ourworkCard2}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={[t("wordslib.beautifying"), t("wordslib.studio")]}
                      image={vivodecoImage}
                      title={[t("wordslib.beautifying"), t("wordslib.studio")]}
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                    <CardContent>
                      <Typography
                        className={classes.textCenter}
                        gutterBottom
                        fontSize="1.0rem"
                        component="h2"
                      >
                        {[t("wordslib.beautifying"), t("wordslib.studio")]}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </div>
                <CardActionArea className={classes.stickBottom}>
                  <CardContent>
                    <Button color="vivoblue" transparent>
                      {t("wordslib.readmore")}
                    </Button>
                  </CardContent>
                </CardActionArea>
              </a>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={6} sm={6} md={3} className={classes.topSpace}>
            <Card className={classes.ourworkCard}>
              <a href="https://works.vivoleo.com/?page_id=1581">
                <div className={classes.ourworkCard2}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={[t("wordslib.training"), t("wordslib.school")]}
                      image={trainingschoolImage}
                      title={[t("wordslib.training"), t("wordslib.school")]}
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                    <CardContent>
                      <Typography
                        className={classes.textCenter}
                        gutterBottom
                        fontSize="1.0rem"
                        component="h2"
                      >
                        {[t("wordslib.training"), t("wordslib.school")]}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </div>
                <CardActionArea className={classes.stickBottom}>
                  <CardContent>
                    <Button color="vivoblue" transparent>
                      {t("wordslib.readmore")}
                    </Button>
                  </CardContent>
                </CardActionArea>
              </a>
            </Card>
          </GridItem>
          <GridItem xs={6} sm={6} md={3} className={classes.topSpace}>
            <Card className={classes.ourworkCard}>
              <a href="https://works.vivoleo.com/?page_id=858">
                <div className={classes.ourworkCard2}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={[t("wordslib.gaming"), t("wordslib.studio")]}
                      image={gamingImage}
                      title={[t("wordslib.gaming"), t("wordslib.studio")]}
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                    <CardContent>
                      <Typography
                        className={classes.textCenter}
                        gutterBottom
                        fontSize="1.0rem"
                        component="h2"
                      >
                        {[t("wordslib.gaming"), t("wordslib.studio")]}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </div>
                <CardActionArea className={classes.stickBottom}>
                  <CardContent>
                    <Button color="vivoblue" transparent>
                      {t("wordslib.readmore")}
                    </Button>
                  </CardContent>
                </CardActionArea>
              </a>
            </Card>
          </GridItem>
          <GridItem xs={6} sm={6} md={3} className={classes.topSpace}>
            <Card className={classes.ourworkCard}>
              <a href="https://works.vivoleo.com/?page_id=1384">
                <div className={classes.ourworkCard2}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={[t("wordslib.shoe"), t("wordslib.warehouse")]}
                      image={shoewarehouseImage}
                      title={[t("wordslib.shoe"), t("wordslib.warehouse")]}
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                    <CardContent>
                      <Typography
                        className={classes.textCenter}
                        gutterBottom
                        fontSize="1.0rem"
                        component="h2"
                      >
                        {[t("wordslib.shoe"), t("wordslib.warehouse")]}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </div>
                <CardActionArea className={classes.stickBottom}>
                  <CardContent>
                    <Button color="vivoblue" transparent>
                      {t("wordslib.readmore")}
                    </Button>
                  </CardContent>
                </CardActionArea>
              </a>
            </Card>
          </GridItem>
          <GridItem xs={6} sm={6} md={3} className={classes.topSpace}>
            <Card className={classes.ourworkCard}>
              <a href="https://works.vivoleo.com/?page_id=875">
                <div className={classes.ourworkCard2}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={[t("wordslib.micromovie"), t("wordslib.studio")]}
                      image={micromovieImage}
                      title={[t("wordslib.micromovie"), t("wordslib.studio")]}
                      className={classes.imgRounded + " " + classes.imgFluid}
                    />
                    <CardContent>
                      <Typography
                        className={classes.textCenter}
                        gutterBottom
                        fontSize="1.0rem"
                        component="h2"
                      >
                        {[t("wordslib.micromovie"), t("wordslib.studio")]}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </div>
                <CardActionArea className={classes.stickBottom}>
                  <CardContent>
                    <Button color="vivoblue" transparent>
                      {t("wordslib.readmore")}
                    </Button>
                  </CardContent>
                </CardActionArea>
              </a>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} className={classes.topSpace}>
            <div className={classes.bottmButton}>
              <Button
                color="vivoblue"
                transparent
                href="https://works.vivoleo.com/?page_id=2085"
                target="_blank"
              >
                {t("wordslib.learnmore")}
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
